@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'rudaw';
  src: url('../public/fonts/rudawbold.ttf') format('woff2'),
       url('../public/fonts/rudawbold.ttf') format('woff');

}

/* @import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Arabic:wght@100..900&display=swap'); */
body{
  padding: 0;
  margin: 0;
  overflow: hidden;
  font-family: 'rudaw', sans-serif;
  @apply font-normal
}



/* Hide scrollbar for Chrome, Safari and Opera */
::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.App {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}


.staffimage{
  max-width:300px !important;
  margin-right:auto !important;
  margin-left:auto !important;
}

.active{
  @apply py-2 px-4 rounded transition-colors bg-[#fca500] duration-300 ease-in-out  font-normal text-[#1b1b1b]
}

.link{

  @apply py-2 px-4 rounded transition-colors duration-300 ease-in-out  hover:bg-[#fca500] font-normal  text-[#1b1b1b]
}


